export enum Actions {
    // SESSION AND TOKEN
    sessionExpired = "[Action] - Session is Expired",
    // REFRESH TOKEN
    refreshTokenRequest = "[Action] - Refresh Token Request",
    refreshTokenSuccess = "[Event] - Refresh Token Success",
    refreshTokenFailure = "[Event] - Refresh Token Failure",
    // GENERAL
    restorePrevSession = "[Action] - Restore Prev Session",
    // AUTH
    attivaAccountRequest = "[Action] - Create Activation Account Request",
    attivaAccountFailure = "[Event] - Create Activation Account Failure",
    attivaAccountSuccess = "[Event] - Create Activation Account Success",
    // LOGIN
    createLoginRequest = "[Action] - Create Login Request",
    createLoginFailure = "[Event] - Create Login Failure",
    createLoginSuccess = "[Event] - Create Login Success",
    // SIGN IN
    createSigninRequest = "[Action] - Create Signin Request",
    createSigninSuccess = "[Event] - Create Signin Success",
    createSigninFailure = "[Event] - Create Signin Failure",
    // LOGOUT
    createLogoutRequest = "[Action] - Create Logout Request",
    createLogoutFailure = "[Event] - Create Logout Failure",
    createLogoutSuccess = "[Event] - Create Logout Success",
    // SIGNUP
    createSignupRequest = "[Action] - Create Signup Request",
    createSignupFailure = "[Event] - Create Signup Failure",
    createSignupSuccess = "[Event] - Create Signup Success",
    // CHANGE PASSWORD
    changePasswordRequest = "[Action] - Change Password Request",
    changePasswordSuccess = "[Event] - Change Password Success",
    changePasswordFailure = "[Event] - Change Password Failure",
    // FORGOT PASSWORD
    forgotPasswordRequest = "[Action] - Forgot Password Request",
    forgotPasswordSuccess = "[Event] - Forgot Password Success",
    forgotPasswordFailure = "[Event] - Forgot Password Failure",
    // RESET PASSWORD
    resetPasswordRequest = "[Action] - Reset Password Request",
    resetPasswordSuccess = "[Event] - Reset Password Success",
    resetPasswordFailure = "[Event] - Reset Password Failure",
    // DELETE ACCOUNT
    userDeleteRequest = "[Action] - Delete User Account Request",
    userDeleteFailure = "[Event] - Delete User Account Failure",
    userDeleteSuccess = "[Event] - Delete User Account Success",
    userDelete = "[Event] - Delete User",
    // DISCONNECT USER
    userDisconnectRequest = "[Action] - Disconnect User Account Request",
    userDisconnectFailure = "[Event] - Disconnect User Account Failure",
    userDisconnectSuccess = "[Event] - Disconnect User Account Success",
    // STUDIO CREATE
    createStudioRequest = "[Action] - Create Studio Request",
    createStudioFailure = "[Event] - Create Studio Failure",
    createStudioSuccess = "[Event] - Create Studio Success",
    // STUDIO DETAIL
    studioDetailRequest = "[Action] - Fetch Studio List Request",
    studioDetailFailure = "[Event] - Fetch Studio List Failure",
    studioDetailSuccess = "[Event] - Fetch Studio List Success",
    // STUDIO LIST
    studioListRequest = "[Action] - Fetch Studio List Request",
    studioListFailure = "[Event] - Fetch Studio List Failure",
    studioListSuccess = "[Event] - Fetch Studio List Success",
    // STUDIO UPDATE
    updateStudioRequest = "[Action] - Update Studio Request",
    updateStudioSuccess = "[Event] - Update Studio Success",
    updateStudioFailure = "[Event] - Update Studio Failure",
    // STUDIO SUBSCRIPTION
    studioSubscriptionRequest = "[Action] - Studio Subscription Request",
    studioSubscriptionSuccess = "[Event] - Studio Subscription Success",
    studioSubscriptionFailure = "[Event] - Studio Subscription Failure",
    // STUDIO SUBSCRIPTION PLANS
    subscriptionPlansRequest = "[Actions] - Fetch Subscription Plans Request",
    subscriptionPlansSuccess = "[Event] - Fetch Subscription Plans Success",
    subscriptionPlansError = "[Event] - Fetch Subscription Plans Error",
    subscriptionPlansSelected = "[Event] - Subscription Plans Selected",
    // STUDIO SUBSCRIPTION CUSTOMER
    studioSubscriptionCustomerFailure = "studioSubscriptionCustomerFailure",
    studioSubscriptionCustomerRequest = "studioSubscriptionCustomerRequest",
    studioSubscriptionCustomerSuccess = "studioSubscriptionCustomerSuccess",
    // STUDIO SUBSCRIPTION CUSTOMER CREATE
    studioSubscriptionCustomerCreateRequest = "[Action] - Studio Subscription Customer Create Request",
    studioSubscriptionCustomerCreateSuccess = "[Event] - Studio Subscription Customer Create Success",
    studioSubscriptionCustomerCreateFailure = "[Event] - Studio Subscription Customer Create Failure",
    // STUDIO SUBSCRIPTION TRIAL CREATE
    studioSubscriptionTrialCreateRequest = "[Action] - Studio Subscription Trial Create Request",
    studioSubscriptionTrialCreateSuccess = "[Event] - Studio Subscription Trial Create Success",
    studioSubscriptionTrialCreateFailure = "[Event] - Studio Subscription Trial Create Failure",
    // STUDIO SUBSCRIPTION INFORMATION
    subscriptionBillingInformationRequest = "[Action] - Fetch  Subscription Billing Information Request",
    subscriptionBillingInformationSuccess = "[Event] - Fetch Subscription Billing Information Success",
    subscriptionBillingInformationError = "[Event] - Fetch Subscription Billing Information Error",
    subscriptionUpdateBillingInformationRequest = "[Action] - Fetch Subscription Update Billing Information Request",
    subscriptionUpdateBillingInformationSuccess = "[Event] - Fetch Subscription Update Billing Information Success",
    subscriptionUpdateBillingInformationError = "[Event] - Fetch Subscription Update Billing Information Error",
    // STUDIO SUBSCRIPTION LIST PAYMENT METHOD
    subscriptionListPaymentMethodRequest = "[Action] - Fetch  Subscription List Payment Method Request",
    subscriptionListPaymentMethodSuccess = "[Event] - Fetch Subscription List Payment Method Success",
    subscriptionListPaymentMethodError = "[Event] - Fetch Subscription List Payment Method Error",
    // STUDIO SUBSCRIPTION RETRIEVE UPCOMING INVOICE
    studioSubscriptionRetrieveUpcomingInvoiceRequest = "[Action] - Fetch  Subscription Retrieve Upcoming Invoice Request",
    studioSubscriptionRetrieveUpcomingInvoiceSuccess = "[Event] - Fetch Subscription Retrieve Upcoming Invoice Success",
    studioSubscriptionRetrieveUpcomingInvoiceFailure = "[Event] - Fetch Subscription Retrieve Upcoming Invoice Error",
    // STUDIO SUBSCRIPTION DELETE USERS
    studioSubscriptionDeliteUsers = "[Action] - Delete Users",
    studioSubscriptionClearDeliteUsers = "[Action] - Clear Delete Users",
    studioSubscriptionDeliteAllUsers = "[Action] - Delete All Users",
    // STUDIO SUBSCRIPTION QUANTITY AND INTERVAL
    subscriptionQuantityAndInterval = "[Event] - Subscription Quantity and Interval",
    // STUDIO SUBSCRIPTION PLAN QUANTITY AND INTERVAL
    subscriptionPlanQuantityAndInterval = "[Event] - Subscription Plan Quantity and Interval",
    // WORK SUMMARY
    documentSummaryRequest = "[Action] - Fetch Document Summary Request",
    documentSummarySuccess = "[Event] - Fetch Document Summary Success",
    documentSummaryFailure = "[Event] - Fetch Document Summary Failure",
    documentSummaryLoading = "[Event] - Document Summary Loading",

    inspectionSummaryRequest = "[Action] - Fetch Inspection Summary Request",
    inspectionSummarySuccess = "[Event] - Fetch Inspection Summary Success",
    inspectionSummaryFailure = "[Event] - Fetch Inspection Summary Failure",
    inspectionSummaryLoading = "[Event] - Inspection Summary Loading",

    annotationSummaryRequest = "[Action] - Fetch Annotation Summary Request",
    annotationSummarySuccess = "[Event] - Fetch Annotation Summary Success",
    annotationSummaryFailure = "[Event] - Fetch Annotation Summary Failure",
    annotationSummaryLoading = "[Event] - Annotation Summary Loading",

    summaryOfCountRequest = "[Action] - Fetch Summary Of Count Request",
    summaryOfCountSuccess = "[Event] - Fetch Summary Of Count Success",
    summaryOfCountFailure = "[Event] - Fetch Summary Of Count Failure",
    summaryOfCountLoading = "[Event] - Summary Of Count Loading",
    // WORK LIST
    workListRequest = "[Action] - Fetch Work List Request",
    workListSuccess = "[Event] - Fetch Work List Success",
    workListFailure = "[Event] - Fetch Work List Failure",
    workListSelected = "[Action] - Fetch Work List Selected",
    // WORK DETAIL
    workDetailRequest = "[Action] - Fetch Work Detail Request",
    workDetailSuccess = "[Event] - Fetch Work Detail Success",
    workDetailFailure = "[Event] - Fetch Work Detail Failure",
    // WORK CREATE
    workCreateRequest = "[Action] - Create Work Request",
    workCreateFailure = "[Event] - Create Work Failure",
    workCreateSuccess = "[Event] - Create Work Success",
    // WORK UPDATE
    workUpdateRequest = "[Action] - Update Work Request",
    workUpdateFailure = "[Event] - Update Work Failure",
    workUpdateSuccess = "[Event] - Update Work Success",
    // WORK DELETE
    workDeleteRequest = "[Action] - Work Delete Request",
    workDeleteSuccess = "[Event] - Work Delete Success",
    workDeleteFailure = "[Event] - Work Delete Failure",
    workDelete = "[Event] - Work Delete",
    // ACTIVITY LIST
    fetchActivityListRequest = "[Action] - Fetch Activity List Request",
    fetchActivityListSuccess = "[Event] - Fetch Activity List Success",
    fetchActivityListFailure = "[Event] - Fetch Activity List Failure",
    // ORDER LIST
    orderListRequest = "[Action] - Fetch Order List Request",
    orderListFailure = "[Event] - Fetch Order List Failure",
    orderListSuccess = "[Event] - Fetch Order List Success",
    // ORDER DETAIL
    fetchOrderDetailRequest = "[Action] - Fetch Order Detail Request",
    fetchOrderDetailSuccess = "[Event] - Fetch Order Detail Success",
    fetchOrderDetailFailure = "[Event] - Fetch Order Detail Failure",
    // ORDER RECALCULATE COSTS
    orderRecalculateCostsRequest = "[Action] - Recalculate order costs Request",
    orderRecalculateCostsFailure = "[Event] - Recalculate order costs Failure",
    orderRecalculateCostsSuccess = "[Event] - Recalculate order costs Success",
    // ORDER CREATE
    createOrderRequest = "[Action] - Create Order Request",
    createOrderSuccess = "[Event] - Create Order Success",
    createOrderFailure = "[Event] - Create Order Failure",
    // ORDER UPDATEE
    orderUpdateRequest = "[Action] - Order Update Request",
    orderUpdateSuccess = "[Event] - Order Update Success",
    orderUpdateFailure = "[Event] - Order Update Failure",
    // ORDER DELETE
    deleteOrderRequest = "[Action] - Delete Order Request",
    deleteOrder = "[Event] - Delete Order",
    deleteOrderSuccess = "[Event] - Delete Order Success",
    deleteOrderFailure = "[Event] - Delete Order Failure",
    // ORDER READ
    orderReadRequest = "[Action] - Order Read Request",
    orderReadSuccess = "[Action] - Order Read Success",
    orderReadFailure = "[Action] - Order Read Failure",
    // ORDER CHECKING
    checkOrderRequest = "[Action] - Check Order Request",
    checkOrderSuccess = "[Event] - Check Order Success",
    checkOrderFailure = "[Event] - Check Order Failure",
    // ORDER NUMBER
    fetchOrderNumberRequest = "[Action] - Fetch Order Number Request",
    fetchOrderNumberSuccess = "[Event] - Fetch Order Number Success",
    fetchOrderNumberFailure = "[Event] - Fetch Order Number Failure",
    // ORDER TEAM
    orderTeamRequest = "[Action] - Order Team Request",
    orderTeamSuccess = "[Event] - Order Team Success",
    orderTeamFailure = "[Event] - Order Team Failure",
    // ORDER FILTER
    oramaSearch = "[Action] - Set Orama Search",
    oramaFiltersSearch = "[Action] - Set Orama Filters Search",
    // INSPECTION LIST
    inspectionListRequest = "[Action] - Fetch Inspection List Request",
    inspectionListSuccess = "[Event] - Fetch Inspection List Success",
    inspectionListFailure = "[Event] - Fetch Inspection List Failure",
    // INSPECTION DETAIL
    inspectionDetailRequest = "[Action] - Inspection Detail Request",
    inspectionDetailSuccess = "[Event] - Inspection Detail Success",
    inspectionDetailFailure = "[Event] - Inspection Detail Failure",
    // INSPECTION DETAIL
    inspectionDownloadPdfRequest = "[Action] - Inspection Download Pdf Request",
    inspectionDownloadPdfSuccess = "[Event] - Inspection Download Pdf Success",
    inspectionDownloadPdfFailure = "[Event] - Inspection Download Pdf Failure",
    // INSPECTION CLEAR
    inspectionDetailClear = "[Action] - Clear inspection",
    // INSPECTION CREATE
    inspectionCreateRequest = "[Action] - Inspection Create Request",
    inspectionCreateSuccess = "[Event] - Inspection Create Success",
    inspectionCreateFailure = "[Event] - Inspection Create Failure",
    inspectionClearLoading = "[Action] - Inspection Clear loading",
    // INSPECTION EDIT
    inspectionEditRequest = "[Action] - Inspection Edit Request",
    inspectionEditSuccess = "[Event] - Inspection Edit Success",
    inspectionEditFailure = "[Event] - Inspection Edit Failure",
    // INSPECTION DELETE
    inspectionDeleteRequest = "[Action] - Inspection Delete Request",
    inspectionDeleteSuccess = "[Event] - Inspection Delete Success",
    inspectionDeleteFailure = "[Event] - Inspection Delete Failure",
    inspectionDelete = "[Action] - Inspection Delete",
    inspectionDeleteLoading = "[Action] - Inspection Loading",
    // INSPECTION PARTECIPANT
    inspectionAddPartecipantRequest = "[Action] - Inspection Partecipant Request",
    inspectionAddPartecipantSuccess = "[Event] - Inspection Partecipant Success",
    inspectionAddPartecipantFailure = "[Event] - Inspection Partecipant Failure",
    // INSPECTION REMARK CREATE
    inspectionListRemarksRequest = "[Action] - Inspection Remark List Request",
    inspectionListRemarksSuccess = "[Event] - Inspection Remark List Success",
    inspectionListRemarksFailure = "[Event] - Inspection Remark List Failure",
    // INSPECTION REMARK LIST
    inspectionRemarkCreateRequest = "[Action] - Inspection Remark Create Request",
    inspectionRemarkCreateSuccess = "[Event] - Inspection Remark Create Success",
    inspectionRemarkCreateFailure = "[Event] - Inspection Remark Create Failure",

    // INSPECTION REMARK EDIT
    inspectionRemarkEditRequest = "[Action] - Inspection Remark Edit Request",
    inspectionRemarkEditSuccess = "[Event] - Inspection Remark Edit Success",
    inspectionRemarkEditFailure = "[Event] - Inspection Remark Edit Failure",
    // INSPECTION DELETE
    inspectionRemarkDeleteRequest = "[Action] - Inspection Remark Delete Request",
    inspectionRemarkDeleteSuccess = "[Event] - Inspection Remark Delete Success",
    inspectionRemarkDeleteFailure = "[Event] - Inspection Remark Delete Failure",
    // INSPECTION SIGNATURE UPLOAD
    inspectionSignatureUploadRequest = "[Action] - Inspection Signature Upload Request",
    inspectionSignatureUploadSuccess = "[Event] - Inspection Signature Upload Success",
    inspectionSignatureUploadFailure = "[Event] - Inspection Signature Upload Failure",
    // INSPECTION SIGNATURE UPLOAD
    inspectionSignatureDeleteRequest = "[Action] - Inspection Signature Delete Request",
    inspectionSignatureDeleteSuccess = "[Event] - Inspection Signature Delete Success",
    inspectionSignatureDeleteFailure = "[Event] - Inspection Signature Delete Failure",
    // TASK CREATE
    taskCreateRequest = "[Action] - Create Task Request",
    taskCreateSuccess = "[Event] - Create Task Success",
    taskCreateFailure = "[Event] - Create Task Failure",
    // TASK LIST
    taskListRequest = "[Action] - Fetch Task List Request",
    taskListSuccess = "[Event] - Fetch Task List Success",
    taskListFailure = "[Event] - Fetch Task List Failure",
    // TASK DETAIL
    taskDetailRequest = "[Action] - Fetch Task Detail Request",
    taskDetailSuccess = "[Event] - Fetch Task Detail Success",
    taskDetailFailure = "[Event] - Fetch Task Detail Failure",
    // TASK DELETE
    taskDeleteRequest = "[Action] - Task Delete Request",
    taskDeleteSuccess = "[Event] - Task Delete Success",
    taskDeleteFailure = "[Event] - Task Delete Failure",
    taskDelete = "[Event] - Task Delete",
    // TASK UPDATE
    taskUpdateRequest = "[Action] - Task Update Request",
    taskUpdateSuccess = "[Event] - Task Update Success",
    taskUpdateFailure = "[Event] - Task Update Failure",
    // TASK LOADING
    taskListLoading = "[Action] - Fetch Task List Loading",
    // TASK TOGGLE CALENDAR
    taskToggleCalendar = "[Action] - Toggle Task Calendar",
    // LOGOUT STUDIO
    logoutStudioRequest = "[Action] - Logout Studio Request",
    logoutStudioSuccess = "[Event] - Logout Studio Success",
    logoutStudioFailure = "[Event] - Logout Studio Failure",
    // STUDIO STATS
    studioStatsRequest = "[Action] - Fetch Studio Stats Request",
    studioStatsSuccess = "[Event] - Fetch Studio Stats Success",
    studioStatsFailure = "[Event] - Fetch Studio Stats Failure",
    // INVITATION SEND
    invitationSendRequest = "[Action] - Invitation Send Request",
    invitationSendSuccess = "[Event] - Invitation Send Success",
    invitationSendFailure = "[Event] - Invitation Send Failure",
    // INVITATION LIST
    invitationListRequest = "[Action] - Invite List Request",
    invitationListSuccess = "[Event] - Invite List Success",
    invitationListFailure = "[Event] - Invite List Failure",
    // INVITATION ACCEPT WITH ANOTHER ACCOUNT
    acceptInvitationWithAnotherAccountRequest = "[Action] - Accept Invitation With Another Account Request",
    acceptInvitationWithAnotherAccountSuccess = "[Event] - Accept Invitation With Another Account Success",
    acceptInvitationWithAnotherAccountFailure = "[Event] - Accept Invitation With Another Account Failure",
    // INVITATION ACCEPT AS USER LOGGED
    acceptInvitationAsUserLoggedRequest = "[Action] - Accept Invitation As User Logged Request",
    acceptInvitationAsUserLoggedSuccess = "[Event] - Accept Invitation As User Logged Success",
    acceptInvitationAsUserLoggedFailure = "[Event] - Accept Invitation As User Logged Failure",
    // INVITE STUDIO
    inviteStudioRequest = "[Action] - Invite Studio Request",
    inviteStudioSuccess = "[Event] - Invite Studio Success",
    inviteStudioFailure = "[Event] - Invite Studio Failure",
    // UPLOAD IMAGE STUDIO
    uploadStudioImageRequest = "[Action] - Upload Studio Image Request",
    uploadStudioImageSuccess = "[Event] - Upload Studio Image Success",
    uploadStudioImageFailure = "[Event] - Upload Studio Image Failure",
    // USER UPDATE
    updateProfiloRequest = "[Action] - Update Profile Request",
    updateProfiloSuccess = "[Event] - Update Profile Success",
    updateProfiloFailure = "[Event] - Update Profile Failure",
    // USER FETCH DETAIL
    userDetailRequest = "[Action] - Fetch User Detail Request",
    userDetailSuccess = "[Event] - Fetch User Detail Success",
    userDetailFailure = "[Event] - Fetch User Detail Failure",
    // USER TITLE
    fetchTitleRequest = "[Action] - Fetch Title Request",
    fetchTitleSuccess = "[Event] - Fetch Title Success",
    fetchTitleFailure = "[Event] - Fetch Title Failure",
    setSelectedTitle = "[Action] - Set Selected Title",
    resetTitleList = "[Event] - Reset Title List",
    // UPLOAD USER IMAGE
    uploadUserImageRequest = "[Action] - Upload User Image Request",
    uploadUserImageSuccess = "[Event] - Upload User Image Success",
    uploadUserImageFailure = "[Event] - Upload User Image Failure",
    // CUSTOMER LIST
    customerListRequest = "[Action] - Fetch Customer List Request",
    customerListSuccess = "[Event] - Fetch Customer List Success",
    customerListFailure = "[Event] - Fetch Customer List Failure",
    // CUSTOMER CREATE
    customerCreateRequest = "[Action] - Create Customer Request",
    customerCreateSuccess = "[Event] - Create Customer Success",
    customerCreateFailure = "[Event] - Create Customer Failure",
    // IMPORT CONTACT
    importContactRequest = "[Action] - Import Contact Request",
    importContactSuccess = "[Event] - Import Contact Success",
    importContactFailure = "[Event] - Import Contact Failure",
    // COMMITMENT CONNECT
    commitmentConnectRequest = "[Event] - Connect Commitment Request",
    commitmentConnectSuccess = "[Event] - Connect Commitment Success",
    commitmentConnectFailure = "[Event] - Connect Commitment Failure",
    // OPERATOR CONNECT
    operatorConnectRequest = "[Event] - Connect Operator Request",
    operatorConnectSuccess = "[Event] - Connect Operator Success",
    operatorConnectFailure = "[Event] - Connect Operator Failure",
    // COMMITMENT CREATE
    commitmentCreateRequest = "[Action] - Create Commitment Request",
    commitmentCreateSuccess = "[Event] - Create Commitment Success",
    commitmentCreateFailure = "[Event] - Create Commitment Failure",
    // COMMITMENT DELETE
    commitmentDeleteRequest = "[Action] - Delete Commitment Request",
    commitmentDeleteSuccess = "[Event] - Delete Commitment Success",
    commitmentDeleteFailure = "[Event] - Delete Commitment Failure",
    commitmentDelete = "[Event] - Delete Commitment Confirm",
    // OPERATOR CREATE
    operatorCreateRequest = "[Action] - Create Operator Request",
    operatorCreateSuccess = "[Event] - Create Operator Success",
    operatorCreateFailure = "[Event] - Create Operator Failure",
    // CUSTOMER UPDATE
    customerUpdateRequest = "[Action] - Update Customer Request",
    customerUpdateSuccess = "[Event] - Update Customer Success",
    customerUpdateFailure = "[Event] - Update Customer Failure",
    customerUpdate = "[Event] - Customer Update",
    // COMMITMENT LIST
    commitmentListRequest = "[Action] - Fetch Commitment List Request",
    commitmentListSuccess = "[Event] - Fetch Commitment List Success",
    commitmentListFailure = "[Event] - Fetch Commitment List Failure",
    // OPERATOR LIST
    operatorListRequest = "[Action] - Fetch Operator List Request",
    operatorListSuccess = "[Event] - Fetch Operator List Success",
    operatorListFailure = "[Event] - Fetch Operator List Failure",
    // OPERATOR DELETE
    operatorDeleteRequest = "[Action] - Delete Operator Request",
    operatorDeleteSuccess = "[Event] - Delete Operator Success",
    operatorDeleteFailure = "[Event] - Delete Operator Failure",
    operatorDelete = "[Event] - Delete Operator Confirm",
    // CACHE
    eliminaLocalStorage = "[Event] - Delete Local Storage",
    eliminaSessionStorage = "[Event] - Delete Session Storage",
    saveToLocalStorageSuccess = "[Event] - Save to Local Storage Success",
    saveToLocalStorageFailure = "[Event] - Save to Local Storage Failure",
    savetoSessionStorage = "[Action] - Save to Session Storage",
    // UI
    resetLoginError = "[Action] - Reset Login Errors",
    resetSignupError = "[Action] - Reset Signup Errors",
    resetNewSignup = "[Action] - Reset new Signup",
    resetSignupRequestSent = "[Action] - Reset Signup Request Sent",
    resetActivationErrors = "[Action] - Reset Activation Errors",
    resetForgotPasswordError = "[Action] - Reset Forgot Password Errors",
    resetEmailForgotPasswordSent = "[Action] - Reset Email Forgot Password Sent",
    resetInvitationRequestAccepted = "[Event] - Reset Invitation Request Accepted",
    resetActivatedAccount = "[Action] - Reset Activated Account",
    resetOrderCreated = "[Action] - Reset New Order Created",
    resetInvitationSent = "[Action] - Reset Invitation Sent",
    uiToggleGlobalNotify = "[Action] - Toggle Global Notify",
    uiFormSwitchDeadlines = "[Action] - Value Form Switch Deadlines ",
    uiEditWorkDetail = "[Action] - Edit Work Detail ",
    uiGeoForm = "[Action] - Geo Form",
    uiGeoFormReset = "[Action] - Geo Form Reset",
    uiIsFormNotSaved = "[Action] - Is Form Not Saved",
    uiCanLeaveForm = "[Action] - Can leave form",
    uiForceOpenDialog = "[Action] - Force Open Dialog",
    uiGoNewRoutes = "[Action] - Go New Routes",
    restoreExternalLink = "[Action] - Restore External Link",
    uiAppVersion = "[Action] - App Version",
    uiFilterSummary = "[Action] - Filter Summary",
    uiRecalculateRights = "[Action] - Recalculate Rights",
    // LOCALE
    updateLocaleRequest = "[Action] - Update Locale Request",
    updateLocaleSuccess = "[Event] - Update Locale Success",
    updateLocaleFailure = "[Event] - Update Locale Failure",
    // TOAST
    showToast = "[Action] - showToast",
    hideToast = "[Event] - hideToast",
    // LOADING
    paymentMethodLoading = "[Action] - Payment Method Loading",
    currentSubscriptionLoading = "[Action] - Current Subscription Loading",
    plansLoading = "[Action] - Plans Loading",
    // POPUP
    showPopup = "[Event] - Show Popup",
    hidePopup = "[Event] - Hide Popup",
    // FETCH STUDIO'S USERS
    fetchUserListRequest = "[Action] - Fetch User List Request",
    fetchUserListSuccess = "[Event] - Fetch User List Success",
    fetchUserListFailure = "[Event] - Fetch User List Failure",
    fetchuserListUpdate = "[Action] - Update User List",
    // CHANGE TEAM MEMBER ROLE
    studioTeamRoleUpdateRequest = "[Action] - Update Team Role Request",
    studioTeamRoleUpdateSuccess = "[Event] - Update Team Role Success",
    studioTeamRoleUpdateFailure = "[Event] - Update Team Role Failure",
    // CHANGE TEAM MEMBER COST
    studioTeamCostUpdateRequest = "[Action] - Update Team Cost Request",
    studioTeamCostUpdateSuccess = "[Event] - Update Team Cost Success",
    studioTeamCostUpdateFailure = "[Event] - Update Team Cost Failure",
    // CONTACT LIST
    contactListRequest = "[Action] - Fetch Contact List Request",
    contactListSuccess = "[Event] - Fetch Contact List Success",
    contactListFailure = "[Event] - Fetch Contact List Failure",
    // CONTACT CREATE
    contactCreateRequest = "[Action] - Create Contact Request",
    contactCreateSuccess = "[Event] - Create Contact Success",
    contactCreateFailure = "[Event] - Create Contact Failure",
    // CONTACT DELETE
    contactDeleteRequest = "[Action] - Delete Contact Request",
    contactDeleteSuccess = "[Event] - Delete Contact Success",
    contactDeleteFailure = "[Event] - Delete Contact Failure",
    contactDelete = "[Event] - Delete Contact Confirm",
    // CONTACT UPDATE
    contactUpdateRequest = "[Action] - Update Contact Request",
    contactUpdateSuccess = "[Event] - Update Contact Success",
    contactUpdateFailure = "[Event] - Update Contact Failure",
    // DICTIONARY DOCUMENT LIST
    dictionaryDocumentListRequest = "[Action] - Dictionary Document List Request",
    dictionaryDocumentListSuccess = "[Event] - Dictionary Document List Success",
    dictionaryDocumentListFailure = "[Event] - Dictionary Document List Failure",
    // DICTIONARY CREATE DOCUMENT
    dictionaryCreateDocumentRequest = "[Action] - Dictionary Create Document Request",
    dictionaryCreateDocumentSuccess = "[Event] - Dictionary Create Document Success",
    dictionaryCreateDocumentFailure = "[Event] - Dictionary Create Document Failure",
    // DICTIONARY ORDER MODEL LIST
    dictionaryOrderListRequest = "[Action] - Dictionary Order List Request",
    dictionaryOrderListSuccess = "[Event] - Dictionary Order List Success",
    dictionaryOrderListFailure = "[Event] - Dictionary Order List Failure",
    // DICTIONARY CREATE ORDER MODEL
    dictionaryOrderCreateRequest = "[Action] - Dictionary Create Order  Request",
    dictionaryOrderCreateSuccess = "[Event] - Dictionary Create Order  Success",
    dictionaryOrderCreateFailure = "[Event] - Dictionary Create Order  Failure",
    // DICTIONARY CREATE ORDER MODEL
    dictionaryOrderEditRequest = "[Action] - Dictionary Edit Order Request",
    dictionaryOrderEditSuccess = "[Event] - Dictionary Edit Order Success",
    dictionaryOrderEditFailure = "[Event] - Dictionary Edit Order Failure",
    // DICTIONARY SEND TYPE LIST
    dictionarySendingListRequest = "[Action] - Dictionary Sending List Request",
    dictionarySendingListSuccess = "[Event] - Dictionary Sending List Success",
    dictionarySendingListFailure = "[Event] - Dictionary Sending List Failure",
    // DICTIONARY CREATE SEND TYPE
    dictionarySendingCreateRequest = "[Action] - Dictionary Sending Create Request",
    dictionarySendingCreateSuccess = "[Event] - Dictionary Sending Create Success",
    dictionarySendingCreateFailure = "[Event] - Dictionary Sending Create Failure",
    // DICTIONARY EDIT SEND TYPE
    dictionarySendingEditRequest = "[Action] - Dictionary Sending Edit Request",
    dictionarySendingEditSuccess = "[Event] - Dictionary Sending Edit Success",
    dictionarySendingEditFailure = "[Event] - Dictionary Sending Edit Failure",
    // DICTIONARY WORK LIST
    dictionaryWorkListRequest = "[Action] - Dictionary Work List Request",
    dictionaryWorkListSuccess = "[Event] - Dictionary Work List Success",
    dictionaryWorkListFailure = "[Event] - Dictionary Work List Failure",
    // DICTIONARY CREATE WORK
    dictionaryWorkCreateRequest = "[Action] - Dictionary Work Create Request",
    dictionaryWorkCreateSuccess = "[Event] - Dictionary Work Create Success",
    dictionaryWorkCreateFailure = "[Event] - Dictionary Work Create Failure",
    // DICTIONARY EDIT WORK
    dictionaryWorkEditRequest = "[Action] - Dictionary Work Edit Request",
    dictionaryWorkEditSuccess = "[Event] - Dictionary Work Edit Success",
    dictionaryWorkEditFailure = "[Event] - Dictionary Work Edit Failure",
    // DICTIONARY CREATE TASK
    dictionaryTaskCreateRequest = "[Action] - Dictionary Task Create Request",
    dictionaryTaskCreateSuccess = "[Event] - Dictionary Task Create Success",
    dictionaryTaskCreateFailure = "[Event] - Dictionary Task Create Failure",
    // DICTIONARY EDIT TASK
    dictionaryTaskEditRequest = "[Action] - Dictionary Task Edit Request",
    dictionaryTaskEditSuccess = "[Event] - Dictionary Task Edit Success",
    dictionaryTaskEditFailure = "[Event] - Dictionary Task Edit Failure",
    // DICTIONARY DELETE TASK
    dictionaryTaskDeleteRequest = "[Action] - Dictionary Task Delete Request",
    dictionaryTaskDeleteSuccess = "[Event] - Dictionary Task Delete Success",
    dictionaryTaskDeleteFailure = "[Event] - Dictionary Task Delete Failure",
    // DICTIONARY OPERATOR LIST
    dictionaryOperatorListRequest = "[Action] - Dictionary Operator List Request",
    dictionaryOperatorListSuccess = "[Event] - Dictionary Operator List Success",
    dictionaryOperatorListFailure = "[Event] - Dictionary Operator List Failure",
    // DICTIONARY CREATE OPERATOR
    dictionaryOperatorCreateRequest = "[Action] - Dictionary Operator Create Request",
    dictionaryOperatorCreateSuccess = "[Event] - Dictionary Operator Create Success",
    dictionaryOperatorCreateFailure = "[Event] - Dictionary Operator Create Failure",
    // DICTIONARY LIST DOCUMENT TYPE
    dictionaryDocumentTypeListRequest = "[Action] - Dictionary Document Type List Request",
    dictionaryDocumentTypeListSuccess = "[Event] - Dictionary Document Type List Success",
    dictionaryDocumentTypeListFailure = "[Event] - Dictionary Document Type List Failure",
    // DICTIONARY LAND REGISTRY TYPE
    dictionaryLandRegistryRequest = "[Action] - Dictionary Land Registry List Request",
    dictionaryLandRegistrySuccess = "[Event] - Dictionary Land Registry List Success",
    dictionaryLandRegistryFailure = "[Event] - Dictionary Land Registry List Failure",
    // DICTIONARY CREATE DOCUMENT TYPE
    dictionaryDocumentTypeCreateRequest = "[Action] - Dictionary Document Type Create Request",
    dictionaryDocumentTypeCreateSuccess = "[Event] - Dictionary Document Type Create Success",
    dictionaryDocumentTypeCreateFailure = "[Event] - Dictionary Document Type Create Failure",
    // DICTIONARY EDIT DOCUMENT TYPE
    dictionaryDocumentTypeEditRequest = "[Action] - Dictionary Document Type Edit Request",
    dictionaryDocumentTypeEditSuccess = "[Event] - Dictionary Document Type Edit Success",
    dictionaryDocumentTypeEditFailure = "[Event] - Dictionary Document Type Edit Failure",
    // DICTIONARY DELETE DOCUMENT TYPE
    dictionaryDocumentTypeDeleteRequest = "[Action] - Dictionary Document Type Delete Request",
    dictionaryDocumentTypeDeleteSuccess = "[Event] - Dictionary Document Type Delete Success",
    dictionaryDocumentTypeDeleteFailure = "[Event] - Dictionary Document Type Delete Failure",
    dictionaryDocumentTypeDelete = "[Event] - Dictionary Document Type Delete Confirm",
    // DICTIONARY EDIT OPERATOR
    dictionaryOperatorEditRequest = "[Action] - Dictionary Operator Edit Request",
    dictionaryOperatorEditSuccess = "[Event] - Dictionary Operator Edit Success",
    dictionaryOperatorEditFailure = "[Event] - Dictionary Operator Edit Failure",
    // DICTIONARY DELETE WORK
    dictionaryWorkDeleteRequest = "[Action] - Dictionary Work Delete Request",
    dictionaryWorkDeleteSuccess = "[Event] - Dictionary Work Delete Success",
    dictionaryWorkDeleteFailure = "[Event] - Dictionary Work Delete Failure",
    dictionaryWorkDelete = "[Event] - Dictionary Work Delete Confirm",
    // DICTIONARY DELETE OPERATOR
    dictionaryOperatorDeleteRequest = "[Action] - Dictionary Operator Delete Request",
    dictionaryOperatorDeleteSuccess = "[Event] - Dictionary Operator Delete Success",
    dictionaryOperatorDeleteFailure = "[Event] - Dictionary Operator Delete Failure",
    dictionaryOperatorDelete = "[Event] - Dictionary Operator Delete Confirm",
    // DICTIONARY COST CENTER LIST
    dictionaryCostCenterListRequest = "[Action] - Fetch Dictionary Cost Center List Request",
    dictionaryCostCenterListSuccess = "[Event] - Fetch Dictionary Cost Center List Success",
    dictionaryCostCenterListFailure = "[Event] - Fetch Dictionary Cost Center List Failure",
    // DICTIONARY COST CENTER CREATE
    dictionaryCostCenterCreateRequest = "[Action] - Dictionary Cost Center Create Request",
    dictionaryCostCenterCreateSuccess = "[Event] - Dictionary Cost Center Create Success",
    dictionaryCostCenterCreateFailure = "[Event] - Dictionary Cost Center Create Failure",
    // DICTIONARY COST CENTER UPDATE
    dictionaryCostCenterUpdateRequest = "[Action] - Dictionary Cost Center Update Request",
    dictionaryCostCenterUpdateSuccess = "[Event] - Dictionary Cost Center Update Success",
    dictionaryCostCenterUpdateFailure = "[Event] - Dictionary Cost Center Update Failure",
    // DICTIONARY COST CENTER DELETE
    dictionaryCostCenterDeleteRequest = "[Action] - Dictionary Cost Center Delete Request",
    dictionaryCostCenterDeleteSuccess = "[Event] - Dictionary Cost Center Delete Success",
    dictionaryCostCenterDeleteFailure = "[Event] - Dictionary Cost Center Delete Failure",
    dictionaryCostCenterDelete = "[Event] - Dictionary Cost Center Delete Confirm",
    // DICTIONARY DELETE ORDER
    dictionaryOrderDeleteRequest = "[Action] - Dictionary Delete Order  Request",
    dictionaryOrderDeleteSuccess = "[Event] - Dictionary Delete Order  Success",
    dictionaryOrderDeleteFailure = "[Event] - Dictionary Delete Order  Failure",
    dictionaryOrderDelete = "[Event] - Dictionary Delete Order Confirm",
    // DICTIONARY DELETE SEND TYPE
    dictionarySendingDeleteRequest = "[Action] - Dictionary Sending Delete Request",
    dictionarySendingDeleteSuccess = "[Event] - Dictionary Sending Delete Success",
    dictionarySendingDeleteFailure = "[Event] - Dictionary Sending Delete Failure",
    dictionarySendingDelete = "[Event] - Dictionary Sending Delete Confirm",
    // DICTIONARY DELETE DOCUMENT
    dictionaryDeleteDocumentRequest = "[Action] - Dictionary Delete Document Request",
    dictionaryDeleteDocumentSuccess = "[Event] - Dictionary Delete Document Success",
    dictionaryDeleteDocumentFailure = "[Event] - Dictionary Delete Document Failure",
    // ACTIONS
    dictionaryModalRow = "[Action] - Dictionary Add Row",
    dictionaryIsLoading = "[Action] - Dictionary Is Loading",
    dictionaryCollapse = "[Action] - Dictionary Collapse",
    // MUNICIPALITY LIST
    municipalityListRequest = "[Action] - Fetch Municipality List Request",
    municipalityListSuccess = "[Event] - Fetch Municipality List Success",
    municipalityListFailure = "[Event] - Fetch Municipality List Failure",
    // PROVINCE LIST
    provinceListRequest = "[Action] - Fetch Province List Request",
    provinceListSuccess = "[Event] - Fetch Province List Success",
    provinceListFailure = "[Event] - Fetch Province List Failure",
    // REGION LIST
    regionListRequest = "[Action] - Fetch Region List Request",
    regionListSuccess = "[Event] - Fetch Region List Success",
    regionListFailure = "[Event] - Fetch Region List Failure",
    // STATE LIST
    stateListRequest = "[Action] - Fetch State List Request",
    stateListSuccess = "[Event] - Fetch State List Success",
    stateListFailure = "[Event] - Fetch State List Failure",
    // STATE LIST
    registerListRequest = "[Action] - Fetch Register List Request",
    registerListSuccess = "[Event] - Fetch Register List Success",
    registerListFailure = "[Event] - Fetch Register List Failure",
    // DOCUMENT TYPE LIST
    documentTypeListRequest = "[Action] - Fetch Document Type List Request",
    documentTypeListSuccess = "[Event] - Fetch Document Type List Success",
    documentTypeListFailure = "[Event] - Fetch Document Type List Failure",
    // DOCUMENT TYPE CREATE
    documentTypeCreateRequest = "[Action] - Create Document Type List Request",
    documentTypeCreateSuccess = "[Event] - Create Document Type List Success",
    documentTypeCreateFailure = "[Event] - Create Document Type List Failure",
    // DOCUMENT TYPE LIST
    orderModelListRequest = "[Action] - Fetch Order Model List Request",
    orderModelListSuccess = "[Event] - Fetch Order Model List Success",
    orderModelListFailure = "[Event] - Fetch Order Model List Failure",
    // ORDER MODEL CREATE
    orderModelCreateRequest = "[Action] - Create Order Model Request",
    orderModelCreateSuccess = "[Event] - Create Order Model Success",
    orderModelCreateFailure = "[Event] - Create Order Model Failure",
    // SENDING TYPE LIST
    sendingTypeListRequest = "[Action] - Fetch Sending Type Request",
    sendingTypeListSuccess = "[Event] - Fetch Sending Type Success",
    sendingTypeListFailure = "[Event] - Fetch Sending Type Failure",
    // SENDING TYPE CREATE
    sendingTypeCreateRequest = "[Action] - Create Sending Type Request",
    sendingTypeCreateSuccess = "[Event] - Create Sending Type Success",
    sendingTypeCreateFailure = "[Event] - Create Sending Type Failure",
    // Working TYPE List
    workingTypeListRequest = "[Action] - Fetch Working Type List Request",
    workingTypeListSuccess = "[Event] - Fetch Working Type List Success",
    workingTypeListFailure = "[Event] - Fetch Working Type List Failure",
    // Working TYPE CREATE
    workingTypeCreateRequest = "[Action] - Create Working Type Request",
    workingTypeCreateSuccess = "[Event] - Create Working Type Success",
    workingTypeCreateFailure = "[Event] - Create Working Type Failure",
    // OPERATOR TYPE LIST
    operatorTypeListRequest = "[Action] - Fetch Operator Type List Request",
    operatorTypeListSuccess = "[Event] - Fetch Operator Type List Success",
    operatorTypeListFailure = "[Event] - Fetch Operator Type List Failure",
    // OPERATOR TYPE CREATE
    operatorTypeCreateRequest = "[Action] - Create Operator List Request",
    operatorTypeCreateSuccess = "[Event] - Create Operator List Success",
    operatorTypeCreateFailure = "[Event] - Create Operator List Failure",
    // OPERATOR TYPE CREATE
    workingByOrderListRequest = "[Action] - Fetch Working By Order List Request",
    workingByOrderListSuccess = "[Event] - Fetch Working By Order List Success",
    workingByOrderListFailure = "[Event] - Fetch Working By Order List Failure",
    // WORKING TYPE DELETE
    workingTypeDeleteRequest = "[Action] - Delete Working Type Request",
    workingTypeDeleteSuccess = "[Event] - Delete Working Type Success",
    workingTypeDeleteFailure = "[Event] - Delete Working Type Failure",
    // OPERATOR TYPE DELETE
    operatorTypeDeleteRequest = "[Action] - Delete Operator Type Request",
    operatorTypeDeleteSuccess = "[Event] - Delete Operator Type Success",
    operatorTypeDeleteFailure = "[Event] - Delete Operator Type Failure",
    // ORDER MODEL DELETE
    orderModelDeleteRequest = "[Action] - Delete Order Model Request",
    orderModelDeleteSuccess = "[Event] - Delete Order Model Success",
    orderModelDeleteFailure = "[Event] - Delete Order Model Failure",
    // SENDING TYPE DELETE
    sendingTypeDeleteRequest = "[Action] - Delete Sending Type Request",
    sendingTypeDeleteSuccess = "[Event] - Delete Sending Type Success",
    sendingTypeDeleteFailure = "[Event] - Delete Sending Type Failure",
    // DOCUMENT TYPE DELETE
    documentTypeDeleteRequest = "[Action] - Delete Document Type Request",
    documentTypeDeleteSuccess = "[Event] - Delete Document Type Success",
    documentTypeDeleteFailure = "[Event] - Delete Document Type Failure",
    // DOCUMENT UPLOAD CUSTOMER
    documentUploadCustomerRequest = "[Action] - Document Upload Customer Request",
    documentUploadCustomerSuccess = "[Event] - Document Upload Customer Success",
    documentUploadCustomerFailure = "[Event] - Document Upload Customer Failure",
    // DOCUMENT UPLOAD INSPECTION
    documentUploadInspectionRequest = "[Action] - Document Upload Inspection Request",
    documentUploadInspectionSuccess = "[Event] - Document Upload Inspection Success",
    documentUploadInspectionFailure = "[Event] - Document Upload Inspection Failure",
    // DOCUMENT UPLOAD INSPECTION REMARK
    documentUploadInspectionRemarkRequest = "[Action] - Document Upload Inspection Remark Request",
    documentUploadInspectionRemarkSuccess = "[Event] - Document Upload Inspection Remark Success",
    documentUploadInspectionRemarkFailure = "[Event] - Document Upload Inspection Remark Failure",
    // DOCUMENT UPLOAD WORK
    documentUploadWorkRequest = "[Action] - Document Upload Work Request",
    documentUploadWorkSuccess = "[Event] - Document Upload Work Success",
    documentUploadWorkFailure = "[Event] - Document Upload Work Failure",
    // DOCUMENT UPLOAD IDEA
    documentUploadIdeaRequest = "[Action] - Document Upload Idea Request",
    documentUploadIdeaSuccess = "[Event] - Document Upload Idea Success",
    documentUploadIdeaFailure = "[Event] - Document Upload Idea Failure",
    // DOCUMENT UPLOAD TERRITORIAL
    documentUploadTerritorialRequest = "[Action] - Document Upload Territorial Request",
    documentUploadTerritorialSuccess = "[Event] - Document Upload Territorial Success",
    documentUploadTerritorialFailure = "[Event] - Document Upload Territorial Failure",
    // DOCUMENT LIST
    documentListRequest = "[Action] - Fetch Document List Request",
    documentListSuccess = "[Event] - Fetch Document List Success",
    documentListFailure = "[Event] - Fetch Document List Failure",
    // DOCUMENT DELETE CUSTOMER
    documentDeleteCustomerRequest = "[Action] - Document Delete Customer Request",
    documentDeleteCustomerSuccess = "[Event] - Document Delete Customer Success",
    documentDeleteCustomerFailure = "[Event] - Document Delete Customer Failure",
    documentDeleteCustomer = "[Event] - Document Delete Customer Confirm",
    // DOCUMENT DELETE INSPECTION
    documentDeleteInspectionRequest = "[Action] - Document Delete Inspection Request",
    documentDeleteInspectionSuccess = "[Event] - Document Delete Inspection Success",
    documentDeleteInspectionFailure = "[Event] - Document Delete Inspection Failure",
    documentDeleteInspection = "[Event] - Document Delete Inspection Confirm",
    // DOCUMENT DELETE INSPECTION REMARK
    documentDeleteInspectionRemarkRequest = "[Action] - Document Delete Inspection Remark Request",
    documentDeleteInspectionRemarkSuccess = "[Event] - Document Delete Inspection Remark Success",
    documentDeleteInspectionRemarkFailure = "[Event] - Document Delete Inspection Remark Failure",
    documentDeleteInspectionRemark = "[Event] - Document Delete Inspection Remark Confirm",
    // DOCUMENT DELETE IDEA
    documentDeleteIdeaRequest = "[Action] - Document Delete Idea Request",
    documentDeleteIdeaSuccess = "[Event] - Document Delete Idea Success",
    documentDeleteIdeaFailure = "[Event] - Document Delete Idea Failure",
    documentDeleteIdea = "[Event] - Document Delete Idea Confirm",
    // ANNOTATION LIST
    annotationListRequest = "[Action] - Fetch Annotation List Request",
    annotationListSuccess = "[Event] - Fetch Annotation List Success",
    annotationListFailure = "[Event] - Fetch Annotation List Failure",
    annotationListLoading = "[Action] - Annotation List Loading",
    // ANNOTATION CREATE
    annotationCreateRequest = "[Action] - Annotation Create Request",
    annotationCreateSuccess = "[Event] - Annotation Create Success",
    annotationCreateFailure = "[Event] - Annotation Create Failure",
    // ANNOTATION DETAIL
    annotationDetailRequest = "[Action] - Annotation Recover Request",
    annotationDetailSuccess = "[Event] - Annotation Recover Success",
    annotationDetailFailure = "[Event] - Annotation Recover Failure",
    // ANNOTATION UPDATE
    annotationUpdateRequest = "[Action] - Annotation Update Request",
    annotationUpdateSuccess = "[Event] - Annotation Update Success",
    annotationUpdateFailure = "[Event] - Annotation Update Failure",
    // ANNOTATION DELETE
    annotationDeleteRequest = "[Action] - Annotation Delete Request",
    annotationDelete = "[Action] - Annotation Delete",
    annotationDeleteSuccess = "[Event] - Annotation Delete Success",
    annotationDeleteFailure = "[Event] - Annotation Delete Failure",
    // ANNOTATION MEMBER
    annotationMemberRequest = "[Action] - Annotation Member Request",
    annotationMemberSuccess = "[Event] - Annotation Member Success",
    annotationMemberFailure = "[Event] - Annotation Member Failure",
    // DOCUMENT WORK LIST
    documentWorkListRequest = "[Action] - Document Work List Request",
    documentWorkListSuccess = "[Event] - Document Work List Success",
    documentWorkListFailure = "[Event] - Document Work List Failure",
    // DOCUMENT WORK CREATE
    documentWorkCreateRequest = "[Action] - Document Work Create Request",
    documentWorkCreateSuccess = "[Event] - Document Work Create Success",
    documentWorkCreateFailure = "[Event] - Document Work Create Failure",
    // DOCUMENT WORK UPDATE
    documentWorkUpdateRequest = "[Action] - Document Work Update Request",
    documentWorkUpdateSuccess = "[Event] - Document Work Update Success",
    documentWorkUpdateFailure = "[Event] - Document Work Update Failure",
    // DOCUMENT WORK DELETE
    documentWorkDeleteRequest = "[Action] - Document Work Delete Request",
    documentWorkDeleteActionRequest = "[Action] - Document Work Delete Action Request",
    documentWorkDeleteSuccess = "[Event] - Document Work Delete Success",
    documentWorkDeleteFailure = "[Event] - Document Work Delete Failure",
    documentWorkDelete = "[Action] - Document Work Delete",
    // FILE SIGNED INFO
    fileSignedInfoRequest = "[Action] - File Signed Info Request",
    fileSignedInfoSuccess = "[Event] - File signed Info Success",
    fileSignedInfoFailure = "[Event] - File signed Info Failure",
    // IDEA LIST
    ideaListRequest = "[Action] - Fetch Idea List Request",
    ideaListSuccess = "[Event] - Fetch Idea List Success",
    ideaListFailure = "[Event] - Fetch Idea List Failure",
    // IDEA CREATE
    ideaCreateRequest = "[Action] - Idea Create Request",
    ideaCreateSuccess = "[Event] - Idea Create Success",
    ideaCreateFailure = "[Event] - Idea Create Failure",
    // IDEA UPDATE
    ideaUpdateRequest = "[Action] - Idea Update Request",
    ideaUpdateSuccess = "[Event] - Idea Update Success",
    ideaUpdateFailure = "[Event] - Idea Update Failure",
    // IDEA DELETE
    ideaDeleteRequest = "[Action] - Idea Delete Request",
    ideaDeleteSuccess = "[Event] - Idea Delete Success",
    ideaDeleteFailure = "[Event] - Idea Delete Failure",
    ideaDelete = "[Action] - Idea Delete",
    // PROTOCOl SEND
    protocolCreateRequest = "[Action] - Protocol Create Request",
    protocolCreateSuccess = "[Event] - Protocol Create Success",
    protocolCreateFailure = "[Event] - Protocol Create Failure",
    // PROTOCOl LIST BY WORK
    protocolListByWorkRequest = "[Action] - Protocol List By Work Request",
    protocolListByWorkSuccess = "[Event] - Protocol List By Work Success",
    protocolListByWorkFailure = "[Event] - Protocol List By Work Failure",
    // PROTOCOL DELETE
    protocolDeleteRequest = "[Action] - Protocol Delete Request",
    protocolDeleteSuccess = "[Event] - Protocol Delete Success",
    protocolDeleteFailure = "[Event] - Protocol Delete Failure",
    // PROTOCOL UPDATE
    protocolUpdateRequest = "[Action] - Protocol Delete Request",
    protocolUpdateSuccess = "[Event] - Protocol Delete Success",
    protocolUpdateFailure = "[Event] - Protocol Delete Failure",
    // PROTOCOl LIST BY ORDER
    protocolListByOrderRequest = "[Action] - Protocol List By Order Request",
    protocolListByOrderSuceess = "[Event] - Protocol List By Order Success",
    protocolListByOrderFailure = "[Event] - Protocol List By Order Failure",
    // UI PROGRESS BAR
    uiSetUploadInfo = "[Action] - Set Uploade Percentage info",
    uiDeleteUploadInfo = "[Event] - Delete Uploade Percentage info",
    // UI INCOMING DOCUMENT
    uiSetIncomingDocument = "[Action] - Set Incoming Document",
    uiDeleteIncomingDocument = "[Event] - Delete Incoming Document",
    // FORM
    formSet = "[Action] - Form Set",
    formDelete = "[Action] - Form Delete",
    // ORDER STATS
    orderStatsRequest = "[Action] - Fetch Order Stats Request",
    orderStatsSuccess = "[Event] - Fetch Order Stats Success",
    orderStatsFailure = "[Event] - Fetch Order Stats Failure",
    // ROOT DICTIONARIS
    rootDictionaries = "[Event] - Root dictionaries on SignIn",
    asyncResourcesLoaded = "[Event] - Async resources loaded",
    // PAYMENT PREVISION LIST
    paymentPrevisionListRequest = "[Action] - Fetch Payment Prevision List Request",
    paymentPrevisionListSuccess = "[Event] - Fetch Payment Prevision List Success",
    paymentPrevisionListFailure = "[Event] - Fetch Payment Prevision List Failure",
    // PAYMENT PREVISION CREATE
    paymentPrevisionCreateRequest = "[Action] - Payment Prevision Create Request",
    paymentPrevisionCreateSuccess = "[Event] - Payment Prevision Create Success",
    paymentPrevisionCreateFailure = "[Event] - Payment Prevision Create Failure",
    // PAYMENT PREVISION UPDATE
    paymentPrevisionUpdateRequest = "[Action] - Payment Prevision Update Request",
    paymentPrevisionUpdateSuccess = "[Event] - Payment Prevision Update Success",
    paymentPrevisionUpdateFailure = "[Event] - Payment Prevision Update Failure",
    // PAYMENT PREVISION DELETE
    paymentPrevisionDeleteRequest = "[Action] - Payment Prevision Delete Request",
    paymentPrevisionDeleteSuccess = "[Event] - Payment Prevision Delete Success",
    paymentPrevisionDeleteFailure = "[Event] - Payment Prevision Delete Failure",
    paymentPrevisionDelete = "[Event] - Payment Prevision Delete",
    // PAYMENT PREVISION MAX
    paymentPrevisionMaxRequest = "[Action] - Fetch Prevision Max Request",
    paymentPrevisionMaxSuccess = "[Event] - Fetch Prevision Max Success",
    paymentPrevisionMaxFailure = "[Event] - Fetch Prevision Max Failure",
    // PAYMENT PREVISION MIN
    paymentPrevisionMinRequest = "[Action] - Fetch Prevision Min Request",
    paymentPrevisionMinSuccess = "[Event] - Fetch Prevision Min Success",
    paymentPrevisionMinFailure = "[Event] - Fetch Prevision Min Failure",
    // PAYMENT EARN LIST
    paymentEarnListRequest = "[Action] - Fetch Payment Earn List Request",
    paymentEarnListSuccess = "[Event] - Fetch Payment Earn List Success",
    paymentEarnListFailure = "[Event] - Fetch Payment Earn List Failure",
    // PAYMENT EARN CREATE
    paymentEarnCreateRequest = "[Action] - Payment Earn Create Request",
    paymentEarnCreateSuccess = "[Event] - Payment Earn Create Success",
    paymentEarnCreateFailure = "[Event] - Payment Earn Create Failure",
    // PAYMENT EARN UPDATE
    paymentEarnUpdateRequest = "[Action] - Payment Earn Update Request",
    paymentEarnUpdateSuccess = "[Event] - Payment Earn Update Success",
    paymentEarnUpdateFailure = "[Event] - Payment Earn Update Failure",
    // PAYMENT EARN DELETE
    paymentEarnDeleteRequest = "[Action] - Payment Earn Delete Request",
    paymentEarnDeleteSuccess = "[Event] - Payment Earn Delete Success",
    paymentEarnDeleteFailure = "[Event] - Payment Earn Delete Failure",
    paymentEarnDelete = "[Event] - Payment Earn Delete",
    // PAYMENT EARN TOTAL
    paymentEarnTotalRequest = "[Action] - Payment Earn Total Request",
    paymentEarnTotalSuccess = "[Event] - Payment Earn Total Success",
    paymentEarnTotalFailure = "[Event] - Payment Earn Total Failure",
    // PAYMENT EARN ONE TOTAL
    paymentEarnOneTotalRequest = "[Action] - Payment Earn One Total Request",
    paymentEarnOneTotalSuccess = "[Event] - Payment Earn One Total Success",
    paymentEarnOneTotalFailure = "[Event] - Payment Earn One Total Failure",
    // PAYMENT USER COST LIST
    paymentCostUserListRequest = "[Action] - Fetch Payment Cost User List Request",
    paymentCostUserListSuccess = "[Event] - Fetch Payment Cost User List Success",
    paymentCostUserListFailure = "[Event] - Fetch Payment Cost User List Failure",
    // PAYMENT COST LIST
    paymentCostListRequest = "[Action] - Fetch Payment Cost List Request",
    paymentCostListSuccess = "[Event] - Fetch Payment Cost List Success",
    paymentCostListFailure = "[Event] - Fetch Payment Cost List Failure",
    // PAYMENT COST CREATE
    paymentCostCreateRequest = "[Action] - Payment Cost Create Request",
    paymentCostCreateSuccess = "[Event] - Payment Cost Create Success",
    paymentCostCreateFailure = "[Event] - Payment Cost Create Failure",
    // PAYMENT COST UPDATE
    paymentCostUpdateRequest = "[Action] - Payment Cost Update Request",
    paymentCostUpdateSuccess = "[Event] - Payment Cost Update Success",
    paymentCostUpdateFailure = "[Event] - Payment Cost Update Failure",
    // PAYMENT COST DELETE
    paymentCostDeleteRequest = "[Action] - Payment Cost Delete Request",
    paymentCostDeleteSuccess = "[Event] - Payment Cost Delete Success",
    paymentCostDeleteFailure = "[Event] - Payment Cost Delete Failure",
    paymentCostDelete = "[Event] - Payment Cost Delete",
    // PAYMENT COST TOTAL
    paymentCostTotalRequest = "[Action] - Payment Cost Total Request",
    paymentCostTotalSuccess = "[Event] - Payment Cost Total Success",
    paymentCostTotalFailure = "[Event] - Payment Cost Total Failure",
    // MAIL SEND
    mailSendRequest = "[Action] - Mail Send Request",
    mailSendSuccess = "[Event] = Mail Send Success",
    mailSendFailure = "[Event] = Mail Send Failure",
    // MAIL FILELIST
    mailFileListRequest = "[Action] - Mail File List Request",
    mailFileListSuccess = "[Event] - Mail File List Success",
    mailFileListFailure = "[Event] = Mail File List Failure",
    // MAIL CODE GENERATOR
    mailCodeGeneratorRequest = "[Action] = Mail Code Generator Request",
    mailCodeGeneratorSuccess = "[Event] = Mail Code Generator Success",
    mailCodeGeneratorFailure = "[Event] = Mail Code Generator Failure",
    // MAIL DOWNLOAD FILE
    mailFIleDownloadRequest = "[Action] = Mail File Download Request",
    mailFIleDownloadSuccess = "[Event] = Mail File Download Success",
    mailFIleDownloadFailure = "[Event] = Mail File Download Failure",
    // NOTE
    noteAddRequest = "[Note] = Note add",
    noteAddSuccess = "[Note] = Note add Success",
    noteAddFailure = "[Note] = Note add Failure",
    noteGetRequest = "[Note] = Note get",
    noteGetSuccess = "[Note] = Note get Success",
    noteGetFailure = "[Note] = Note get Failure",
    noteDeleteRequest = "[Note] = Note delete",
    noteDeleteSuccess = "[Note] = Note delete Success",
    noteDeleteFailure = "[Note] = Note delete Failure",
    noteCurrent = "[Note] = Note Current",
    // OPERATOR CREATE
    operatorUpdateInQualityOfRequest = "[Action] - Create Update In Quality Of Request",
    operatorUpdateInQualityOfSuccess = "[Event] - Create Update In Quality Of Success",
    operatorUpdateInQualityOfFailure = "[Event] - Create Update In Quality Of Failure",
    // SUBSCRIPTION
    uiSubscriptionModalOpen = "[Action] - Open Subscription Modal",
    uiSubscriptionModalClose = "[Action] - Close Subscription Modal",
    // TERRITORIAL LAND REGISTRY CREATE
    landRegistryCreateRequest = "[Action] = Land Registry Create Request",
    landRegistryCreateSuccess = "[Event] = Land Registry Create Success",
    landRegistryCreateFailure = "[Event] = Land Registry Create Failure",
    // TERRITORIAL LAND REGISTRY LIST
    landRegistryListRequest = "[Action] = Land Registry List Request",
    landRegistryListSuccess = "[Event] = Land Registry List Success",
    landRegistryListFailure = "[Event] = Land Registry List Failure",
    // TERRITORIAL LAND REGISTRY DELETE
    landRegistryDelete = "[Action] = Land Registry Delete",
    landRegistryDeleteRequest = "[Action] = Land Registry Delete Request",
    landRegistryDeleteSuccess = "[Event] = Land Registry Delete Success",
    landRegistryDeleteFailure = "[Event] = Land Registry Delete Failure",
    // TERRITORIAL LAND REGISTRY UPDATE
    landRegistryUpdateRequest = "[Action] = Land Registry Update Request",
    landRegistryUpdateSuccess = "[Event] = Land Registry Update Success",
    landRegistryUpdateFailure = "[Event] = Land Registry Update Failure",
    // TERRITORIAL URBAN TOOL AREA CREATE
    urbanToolAreaCreateRequest = "[Action] = Urban Tool Area Create Request",
    urbanToolAreaCreateSuccess = "[Event] = Urban Tool Area Create Success",
    urbanToolAreaCreateFailure = "[Event] = Urban Tool Area Create Failure",
    // TERRITORIAL URBAN TOOL AREA LIST
    urbanToolAreaListRequest = "[Action] = Urban Tool Area List Request",
    urbanToolAreaListSuccess = "[Event] = Urban Tool Area List Success",
    urbanToolAreaListFailure = "[Event] = Urban Tool Area List Failure",
    // TERRITORIAL URBAN TOOL AREA DELETE
    urbanToolAreaDelete = "[Action] = Urban Tool Area Delete",
    urbanToolAreaDeleteRequest = "[Action] = Urban Tool Area Delete Request",
    urbanToolAreaDeleteSuccess = "[Event] = Urban Tool Area Delete Success",
    urbanToolAreaDeleteFailure = "[Event] = Urban Tool Area Delete Failure",
    // TERRITORIAL URBAN TOOL AREA UPDATE
    urbanToolAreaUpdateRequest = "[Action] = Urban Tool Area Update Request",
    urbanToolAreaUpdateSuccess = "[Event] = Urban Tool Area Update Success",
    urbanToolAreaUpdateFailure = "[Event] = Urban Tool Area Update Failure",
    urbanToolAreaLoading = "[Action] = Urban Tool Area Update Loading",
    // TERRITORIAL DOCUMENT LIST
    territorialDocumentListRequest = "[Action] - Fetch Territorial Document List Request",
    territorialDocumentListSuccess = "[Event] - Fetch Territorial Document List Success",
    territorialDocumentListFailure = "[Event] - Fetch Territorial Document List Failure",
    // TERRITORIAL DOCUMENT CREATE
    territorialDocumentCreateRequest = "[Action] - Territorial Document Create Request",
    territorialDocumentCreateSuccess = "[Event] - Territorial Document Create Success",
    territorialDocumentCreateFailure = "[Event] - Territorial Document Create Failure",
    // TERRITORIAL DOCUMENT UPDATE
    territorialDocumentUpdateRequest = "[Action] - Territorial Document Update Request",
    territorialDocumentUpdateSuccess = "[Event] - Territorial Document Update Success",
    territorialDocumentUpdateFailure = "[Event] - Territorial Document Update Failure",
    // TERRITORIAL DOCUMENT DELETE
    territorialDocumentDeleteRequest = "[Action] - Territorial Document Delete Request",
    territorialDocumentDeleteSuccess = "[Event] - Territorial Document Delete Success",
    territorialDocumentDeleteFailure = "[Event] - Territorial Document Delete Failure",
    territorialDocumentDelete = "[Event] - Territorial Document Delete",
    // TERRITORIAL GEO CREATE
    geoCreateRequest = "[Action] - Geo Create Request",
    geoCreateSuccess = "[Event] - Geo Create Success",
    geoCreateFailure = "[Event] - Geo Create Failure",
    // TERRITORIAL GEO LIST
    geoListRequest = "[Action] - Geo List Request",
    geoListSuccess = "[Event] - Geo List Success",
    geoListFailure = "[Event] - Geo List Failure",
    // TERRITORIAL ADDRESS CREATE
    addressCreateRequest = "[Action] - Address Create Request",
    addressCreateSuccess = "[Event] - Address Create Success",
    addressCreateFailure = "[Event] - Address Create Failure",
    // TERRITORIAL ADDRESS LIST
    addressListRequest = "[Action] - Address List Request",
    addressListSuccess = "[Event] - Address List Success",
    addressListFailure = "[Event] - Address List Failure",
    // TERRITORIAL ADDRESS LIST
    addressUpdateRequest = "[Action] - Address Update Request",
    addressUpdateCap = "[Action] - Address Update Cap",
    addressUpdateSuccess = "[Event] - Address Update Success",
    addressUpdateFailure = "[Event] - Address Update Failure",
    // ADDRESS BOOK LIST
    addressBookListRequest = "[Action] - Fetch Address Book List Request",
    addressBookListSuccess = "[Event] - Fetch Address Book List Success",
    addressBookListFailure = "[Event] - Fetch Address Book List Failure",
    addressBookListLoading = "[Event] - Fetch Address Book List Loading",
    // ADDRESS BOOK CREATE
    addressBookCreateRequest = "[Action] - Address Book Create Request",
    addressBookCreateSuccess = "[Event] - Address Book Create Success",
    addressBookCreateFailure = "[Event] - Address Book Create Failure",
    // ADDRESS BOOK UPDATE
    addressBookUpdateRequest = "[Action] - Address Book Update Request",
    addressBookUpdateSuccess = "[Event] - Address Book Update Success",
    addressBookUpdateFailure = "[Event] - Address Book Update Failure",
    addressBookUpdate = "[Event] - Address Book Update",
    // ADDRESS BOOK UPLOAD
    addressBookUploadRequest = "[Action] - Address Book Upload Request",
    addressBookUploadSuccess = "[Event] - Address Book Upload Success",
    addressBookUploadFailure = "[Event] - Address Book Upload Failure",
    // ADDRESS BOOK DELETE
    addressBookDeleteRequest = "[Action] - Address Book Delete Request",
    addressBookDeleteSuccess = "[Event] - Address Book Delete Success",
    addressBookDeleteFailure = "[Event] - Address Book Delete Failure",
    // ADDRESS BOOK DELETE FILE
    addressBookDeleteFileRequest = "[Action] - Address Book Delete File Request",
    addressBookDeleteFileSuccess = "[Event] - Address Book Delete File Success",
    addressBookDeleteFileFailure = "[Event] - Address Book Delete File Failure",
    addressBookDelete = "[Event] - Address Book Delete",
    // NOTIFICATION CONFIG LIST
    notificationConfigListRequest = "[Action] - Fetch Notification Config List Request",
    notificationConfigListSuccess = "[Event] - Fetch Notification Config List Success",
    notificationConfigListFailure = "[Event] - Fetch Notification Config List Failure",
    // NOTIFICATION CONFIG UPDATE
    notificationConfigUpdateRequest = "[Action] - Notification Config Update Request",
    notificationConfigUpdateSuccess = "[Event] - Notification Config Update Success",
    notificationConfigUpdateFailure = "[Event] - Notification Config Update Failure",
    // NOTIFICATION RECEIVE
    notificationReceive = "[Action] - Notification Receive",
    notificationIncrement = "[Action] - Notification Increment",
    notificationDecrement = "[Action] - Notification Decrement",
    notificationReload = "[Action] - Notification Reload",
    // NOTIFICATION READ
    notificationReadRequest = "[Action] - Notification Read Request",
    notificationReadSuccess = "[Event] - Notification Read Success",
    notificationReadFailure = "[Event] - Notification Read Failure",
    notificationReadListDecrement = "[Action] - Array Notifications Read List Decrement",
    // NOTIFICATION UNREAD
    notificationUnreadRequest = "[Action] - Notification Unread Request",
    notificationUnreadSuccess = "[Event] - Notification Unread Success",
    notificationUnreadFailure = "[Event] - Notification Unread Failure",
    arrayNotificationsUnreadRequest = "[Action] - Array Notifications Unread Request",
    arrayNotificationsUnreadSuccess = "[Action] - Array Notifications Unread Success",
    arrayNotificationsUnreadFailure = "[Action] - Array Notifications Unread Failure",
    arrayNotificationsLastUnreadRequest = "[Action] - Array Notifications Last Unread Request",
    arrayNotificationsLastUnreadSuccess = "[Action] - Array Notifications Last Unread Success",
    arrayNotificationsLastUnreadFailure = "[Action] - Array Notifications Last Unread Failure",
    notificationUnreadListDecrement = "[Action] - Array Notifications Unread List Decrement",
    // NOTIFICATION ALL
    notificationAllRequest = "[Action] - Notification All Request",
    notificationAllSuccess = "[Event] - Notification All Success",
    notificationAllFailure = "[Event] - Notification All Failure",
    // DIRITTI REQUEST
    utentiDirittiRequest = "[Action] - Fetch Utenti Diritti Request",
    utentiDirittiSuccess = "[Event] - Fetch Utenti Diritti Success",
    utentiDirittiFailure = "[Event] - Fetch Utenti Diritti Failure",
    utentiDirittiCommessaRequest = "[Action] - Fetch Utenti Diritti Commessa Request",
    utentiDirittiCommessaSuccess = "[Event] - Fetch Utenti Diritti Commessa Success",
    utentiDirittiCommessaFailure = "[Event] - Fetch Utenti Diritti Commessa Failure",
    // PAPERWORK IMPORT
    paperworkImportRequest = "[Action] - Paperwork Import Request",
    paperworkImportSuccess = "[Event] - Paperwork Import Success",
    paperworkImportFailure = "[Event] - Paperwork Import Failure",
    // PAPERWORK EXPORT
    paperworkExportRequest = "[Action] - Paperwork Export Request",
    // TAG
    tagListRequest = "[Action] - Tag List Request",
    tagListSuccess = "[Event] - Tag List Success",
    tagListFailure = "[Event] - Tag List Failure",
}

export const utilityIsRequestToServer = (type: Actions): boolean =>
    type !== Actions.createLoginRequest && type.includes("Request");

export interface TAction<T = Actions> {
    type: T;
    payload?: any;
}

export interface TError {
    error: string;
    message: {
        message: string;
        details: string[];
    };
}

// export const put = (action: TAction): any => putEffect(action);
