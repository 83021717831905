import { OrdinamentoBE, RicercaLibera } from "@magoot-sdk/api/riepilogo/params";
import { TAnnotation } from "@magoot-sdk/redux-modules/reducers/reducerAnnotations/declarations";
import { TInspection } from "@magoot-sdk/redux-modules/reducers/reducerInspections/declarations";
import { TTask } from "@magoot-sdk/redux-modules/reducers/reducerTask/declarations";
import { TWork } from "@magoot-sdk/redux-modules/reducers/reducerWorks/declarations";

import { TOption } from "@components-ui/Select";

export const NAME_APP = "Magoot";

export const DURATION_TOKEN = 120000;

export const USER_PENDING = 0;
export const USER_ACTIVE = 1;
export const USER_DELETED = 2;

export const ORDER_STATUS_0 = "Qualsiasi stato";
export const ORDER_STATUS_PROGRESS = "In corso";
export const ORDER_STATUS_TO_DO = "Da fare";
export const ORDER_STATUS_DONE = "Completata";
export const ORDER_STATUS_FINISH = "Terminata";
export const ORDER_STATUS_UNDER_EVALUATION = "In valutazione";
export const ORDER_STATUS_LATE = "In ritardo";
export const ORDER_STATUS_EXPIRING = "In scadenza";
export const ORDER_STATUS_CANCELED = "Annullata";
export const ORDER_STATUS_SUSPENDED = "Sospesa";
export const ORDER_STATUS_NOT_EXPIRED = "Nei tempi";
export const ORDER_STATUS_EXPIRED = "Scadute";
export const TASK_STATUS_EXPIRED = "Scaduta";

export type StatusColor = "base" | "warning" | "error" | "success" | "gray" | "tundora-400/75" | "";
export type OrderStatusColor =
    | "errorDark"
    | "success"
    | "secondaryDark"
    | "secondaryDarkLight"
    | "warning"
    | "tundora-400/75";

export type TStatus =
    | typeof ORDER_STATUS_0
    | typeof ORDER_STATUS_TO_DO
    | typeof ORDER_STATUS_DONE
    | typeof ORDER_STATUS_LATE
    | typeof ORDER_STATUS_EXPIRING
    | typeof ORDER_STATUS_SUSPENDED
    | typeof ORDER_STATUS_PROGRESS
    | typeof ORDER_STATUS_CANCELED
    | typeof ORDER_STATUS_NOT_EXPIRED
    | typeof ORDER_STATUS_EXPIRED
    | typeof TASK_STATUS_EXPIRED;

export enum OrderStatus {
    DaFare = 1,
    InCorso = 2,
    Sospesa = 3,
    Annullata = 4,
    Completata = 5,
    InValutazione = 6,
    Bozza = 7,
}

export type TActivitiesStatus =
    | typeof ORDER_STATUS_PROGRESS
    | typeof ORDER_STATUS_TO_DO
    | typeof ORDER_STATUS_FINISH
    | typeof ORDER_STATUS_EXPIRED
    | typeof ORDER_STATUS_EXPIRING;

export const KEY_AUTH = "magoot-auth";

export const optionsOrderExternalTeam = [
    {
        name: "Utente in lettura",
        value: 1,
    },
    {
        name: "Utente in scrittura",
        value: 2,
    },
];

export const optionsOrderTeam = [
    {
        name: "Capo Commessa",
        value: 0,
    },
    {
        name: "Utente in lettura",
        value: 1,
    },
    {
        name: "Utente in scrittura",
        value: 2,
    },
];

export const optionsAdminOrderTeam = [
    {
        name: "Capo Commessa",
        value: 0,
    },
    {
        name: "Utente in scrittura",
        value: 2,
    },
];

export const orderStatusOptions = [
    {
        value: 1,
        name: ORDER_STATUS_PROGRESS,
    },
    {
        value: 2,
        name: ORDER_STATUS_DONE,
    },
];

export const KEY_ORDER_BY_0 = 0;
export const KEY_ORDER_BY_NAME = 1;
export const KEY_ORDER_BY_CREATION_DATE = 2;
export const KEY_ORDER_BY_LAST_ACCESS = 3;
export const KEY_ORDER_BY_STATUS_ORDER = 4;
export const KEY_ORDER_BY_TYPE_ORDER = 5;
export const KEY_ORDER_BY_TYPE_ID = 6;
export const KEY_ORDER_BY_TYPE_YEAR = 7;
export const KEY_TASK_BY_EXPIRED_DATE = 8;
export const KEY_TASK_BY_NAME = 9;
export const KEY_FILTER_ALL_ITEMS = -1;
export const KEY_FILTER_MY_ITEMS = -2;

export type TOrderBy =
    | typeof KEY_ORDER_BY_0
    | typeof KEY_ORDER_BY_NAME
    | typeof KEY_ORDER_BY_CREATION_DATE
    | typeof KEY_ORDER_BY_LAST_ACCESS
    | typeof KEY_ORDER_BY_STATUS_ORDER
    | typeof KEY_ORDER_BY_TYPE_ORDER;

export type TType =
    | typeof ORDER_STATUS_0
    | typeof ORDER_STATUS_NOT_EXPIRED
    | typeof ORDER_STATUS_EXPIRING
    | typeof ORDER_STATUS_EXPIRED
    | typeof ORDER_STATUS_PROGRESS
    | typeof ORDER_STATUS_DONE;

export const optionsOrderBy: TOption[] = [
    {
        name: "Ultimo accesso",
        value: KEY_ORDER_BY_LAST_ACCESS,
    },
    {
        name: "Nome commessa",
        value: KEY_ORDER_BY_NAME,
    },
    {
        name: "Data creazione",
        value: KEY_ORDER_BY_CREATION_DATE,
    },
    {
        name: "Numero commessa",
        value: KEY_ORDER_BY_TYPE_ID,
    },
    {
        name: "Anno commessa",
        value: KEY_ORDER_BY_TYPE_YEAR,
    },
];

export const optionsRicercaLibera: TOption[] = [
    {
        name: "Nome commessa",
        value: RicercaLibera.CommessaDati,
    },
    {
        name: "Numero",
        value: RicercaLibera.CommessaNumeroAnno,
    },
    {
        name: "Committenti",
        value: RicercaLibera.Committenti,
    },
    {
        name: "Indirizzo",
        value: RicercaLibera.Indirizzo,
    },
];

export const optionsRicercaLiberaAttivita: TOption[] = [
    {
        name: "Nome commessa",
        value: RicercaLibera.CommessaDati,
    },
    {
        name: "Numero commessa",
        value: RicercaLibera.CommessaNumeroAnno,
    },
    {
        name: "Titolo o descr attività",
        value: RicercaLibera.AttivitaDati,
    },
];

export const optiosAttivitaStatus = [
    {
        name: ORDER_STATUS_TO_DO,
        value: OrderStatus.DaFare,
    },
    {
        name: ORDER_STATUS_PROGRESS,
        value: OrderStatus.InCorso,
    },
    {
        name: ORDER_STATUS_FINISH,
        value: OrderStatus.Completata,
    },
];

export const optionsOrderByBE: TOption[] = [
    {
        name: "Ultimo accesso",
        value: 12,
    },
    {
        name: "Nome commessa",
        value: OrdinamentoBE.NomeCommessa,
    },
    {
        name: "Data creazione",
        value: OrdinamentoBE.DataCreazione,
    },
    {
        name: "Numero commessa",
        value: OrdinamentoBE.NumeroCommessa,
    },
    {
        name: "Anno commessa",
        value: OrdinamentoBE.AnnoCommessa,
    },
];

export const optionsOrderAttivitaByBE: TOption[] = [
    {
        name: "Nome commessa",
        value: OrdinamentoBE.NomeCommessa,
    },
    {
        name: "Anno commessa",
        value: OrdinamentoBE.AnnoCommessa,
    },
    {
        name: "Titolo attività",
        value: OrdinamentoBE.TitoloAttivita,
    },
    {
        name: "Data scadenza attività",
        value: OrdinamentoBE.DataScadenzaAttivita,
    },
];

export const optionsTaskBy: TOption[] = [
    {
        name: "Data scadenza",
        value: KEY_TASK_BY_EXPIRED_DATE,
    },
    {
        name: "Nome attività",
        value: KEY_TASK_BY_NAME,
    },
    {
        name: "Nome commessa",
        value: KEY_ORDER_BY_NAME,
    },
];

export const optionsSoggetti: TOption[] = [
    {
        name: "Tutti i contatti",
        value: 0,
    },
    {
        name: "Committenti associati ad almeno una commessa",
        value: 1,
    },
    {
        name: "Operatori associati ad almeno una commessa",
        value: 2,
    },
    {
        name: "Committenti/Operatori associati ad almeno una commessa",
        value: 3,
    },
];

export const KEY_COMMITMENT_PERSON = "F";
export const KEY_COMMITMENT_COMPANY = "G";

export const KEY_STATUS_TASK_TO_DO = 1;
export const KEY_STATUS_TASK_DOING = 2;
export const KEY_STATUS_TASK_STOPPED = 3;
export const KEY_STATUS_TASK_CANCELED = 4;
export const KEY_STATUS_TASK_DONE = 5;

export const KEY_DEFAULT_START_DATE_AGO = 180;

export const KEY_ANNOTATION_CALL = 1;
export const KEY_ANNOTATION_MEETING = 2;

export const KEY_PERMISSION_SUPERVISOR = 0;
export const KEY_PERMISSION_USER_IN_READING = 1;
export const KEY_PERMISSION_USER_IN_WRITING = 2;

export const KEY_CODE_VERIFICATED = 1;

// #region ::: TABLEE TYPE OF DICTIONARY
export const COST_CENTER_TYPE = 1;
export const SENDING_TYPE = 2;
export const ORDER_TYPE = 3;
export const DOCUMENT_TYPE = 4;
export const WORK_TYPE = 5;
export const OPERATOR_TYPE = 6;
export const WORK = 10;
export const PREVISION = 11;
export const EARN = 12;
export const COST = 13;

export type TRecordTable =
    | typeof COST_CENTER_TYPE
    | typeof SENDING_TYPE
    | typeof ORDER_TYPE
    | typeof DOCUMENT_TYPE
    | typeof WORK_TYPE
    | typeof OPERATOR_TYPE
    | typeof WORK
    | typeof PREVISION
    | typeof EARN
    | typeof COST;

export type TDictionaryTable =
    | typeof SENDING_TYPE
    | typeof ORDER_TYPE
    | typeof WORK_TYPE
    | typeof OPERATOR_TYPE
    | typeof COST_CENTER_TYPE;
// #endregion

export const ID_OWNER_STUDIO = 1;

export enum StudioStatus {
    daFare = 1,
    inCorso = 2,
    sospesa = 3,
    annullata = 4,
    terminato = 5,
}

export const OptionsCommessa: TOption[] = [
    {
        value: OrderStatus.InCorso,
        name: ORDER_STATUS_PROGRESS,
    },
    {
        value: OrderStatus.Sospesa,
        name: Object.values(OrderStatus)[2].toString(),
    },
    {
        value: OrderStatus.Annullata,
        name: Object.values(OrderStatus)[3].toString(),
    },
    {
        value: OrderStatus.InValutazione,
        name: ORDER_STATUS_UNDER_EVALUATION,
    },
    {
        value: OrderStatus.Bozza,
        name: Object.values(OrderStatus)[6].toString(),
    },
    {
        value: OrderStatus.Completata,
        name: ORDER_STATUS_DONE,
    },
];

export const GEO_ITALY_LAT = 45.5974736;
export const GEO_ITALY_LNG = 9.418361;

export const isOrderStatusExpired = (statoEsecuzione: TStatus): boolean => {
    return statoEsecuzione === ORDER_STATUS_EXPIRED || statoEsecuzione === TASK_STATUS_EXPIRED;
};

export const isOrderStatusExpiring = (statoEsecuzione: TStatus): boolean => {
    return statoEsecuzione === ORDER_STATUS_EXPIRING || statoEsecuzione === ORDER_STATUS_SUSPENDED;
};

export const isOrderStatusToDo = (statoEsecuzione: TStatus): boolean => {
    return statoEsecuzione === ORDER_STATUS_TO_DO || statoEsecuzione === ORDER_STATUS_PROGRESS;
};

export const isOrderStatusDone = (statoEsecuzione: TStatus): boolean => {
    return statoEsecuzione === ORDER_STATUS_DONE;
};

export const isOrderStatusLate = (statoEsecuzione: TStatus): boolean => {
    return statoEsecuzione === ORDER_STATUS_LATE || statoEsecuzione === ORDER_STATUS_CANCELED;
};

export const isOrderStatusNotExpired = (statoEsecuzione: TStatus): boolean => {
    return statoEsecuzione === ORDER_STATUS_NOT_EXPIRED;
};

export const getStatusColor = (statoEsecuzione: TStatus): OrderStatusColor => {
    return (
        (isOrderStatusExpired(statoEsecuzione) && "errorDark") ||
        (isOrderStatusExpiring(statoEsecuzione) && "warning") ||
        (isOrderStatusToDo(statoEsecuzione) && "success") ||
        (isOrderStatusDone(statoEsecuzione) && "secondaryDark") ||
        (isOrderStatusLate(statoEsecuzione) && "errorDark") ||
        (isOrderStatusNotExpired(statoEsecuzione) && "success") ||
        "secondaryDarkLight"
    );
};

export const isTask = (value: TTask | TInspection | TAnnotation): boolean => {
    return "statoAttivita" in value || "dataScadenza" in value;
};

export const isAnnotation = (value: TTask | TInspection | TAnnotation): boolean => {
    return "idTipoAnnotazione" in value;
};

export const isInspection = (value: TTask | TInspection | TAnnotation): boolean => {
    return "relazione" in value;
};

export const taskStatusColor = (task?: TTask | TInspection | TAnnotation): StatusColor => {
    if (!task || (isTask(task) && !(task as TTask).statoAttivita)) return "base";
    if (
        isTask(task) &&
        (task as TTask).statoAttivita === ORDER_STATUS_LATE &&
        (task as TTask).statoEsecuzione.id !== OrderStatus.Completata
    )
        return "error";
    if (
        isTask(task) &&
        (task as TTask).statoAttivita === ORDER_STATUS_EXPIRING &&
        (task as TTask).statoEsecuzione.id !== OrderStatus.Completata
    )
        return "warning";
    if (isTask(task) && (task as TTask).statoEsecuzione.id === OrderStatus.Completata)
        return "gray";
    if (isAnnotation(task) || isInspection(task)) return "";

    return "success";
};

export const workStatusColor = (work?: TWork): StatusColor => {
    if (!work || !work.statoLavorazione) return "base";

    if (work?.statoEsecuzione?.id === OrderStatus.Completata) return "tundora-400/75";
    if (work.statoLavorazione === ORDER_STATUS_LATE) return "error";
    if (work.statoLavorazione === ORDER_STATUS_EXPIRING) return "warning";

    return "success";
};
